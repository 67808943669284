import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Input, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Spinner } from 'reactstrap';
import axios from 'axios';

export const FormsAndDocuments = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [originalData, setOriginalData] = useState({ documents: [] }); // State to hold the original data
    const [filteredData, setFilteredData] = useState({ documents: [] }); // State to hold the filtered data
    const [modal, setModal] = useState(false); // State to manage the modal visibility
    const [update, setUpdate] = useState(false)
    const [formData, setFormData] = useState({
        dName: '',
        dkeywords: '',
        sortLetter: '',
        dtype: '',
        folderLetter: '',
        sampleFile: null
    });

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filtered = originalData.documents.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData({ documents: filtered });
    };

    useEffect(() => {
        axios.get('https://hdhr-apps-api.herokuapp.com/admin/get-documents')
            .then(response => {
                // handle success
                const documents = response.data.documents;
                console.log(documents);
                setOriginalData(documents); // Set the original data
                setFilteredData(documents); // Initially, set the filtered data to the original data
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, [update]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            sampleFile: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        setUploading(true)
        e.preventDefault();
        const data = new FormData();
        data.append('dName', formData.dName);
        data.append('dkeywords', formData.dkeywords);
        data.append('sortLetter', formData.sortLetter);
        data.append('dtype', formData.dtype);
        data.append('folderLetter', formData.folderLetter);
        data.append('sampleFile', formData.sampleFile);

        try {
            const response = await fetch('https://hdhr-apps-api.herokuapp.com/admin/addNewDoc', {
                method: 'POST',
                body: data
            });

            console.log(response);

            const result = await response.json();

            if (result.success) {
                alert('Document uploaded successfully');
                setModal(false);
                setUpdate(!update)
                setUploading(false)
            } else {
                alert('Failed to upload document');
            }
        } catch (error) {
            console.error('Error uploading document:', error);
            alert('An error occurred while uploading the document');
            setUploading(false)

        }
    };

    const handleDelete = async (location) => {
        if (!window.confirm('Are you sure you want to delete this document?')) {
            return;
        }

        try {
            const response = await axios.delete('https://hdhr-apps-api.herokuapp.com/admin/delete-document', { data: { location } });
            if (response.data.success) {
                alert('Document deleted successfully');
                // Update the state to remove the deleted document
                const updatedDocuments = originalData.documents.filter(doc => doc.location !== location);
                setOriginalData({ documents: updatedDocuments });
                setFilteredData({ documents: updatedDocuments });
            } else {
                alert('Failed to delete document');
            }
        } catch (error) {
            console.error('Error deleting document:', error);
            alert('An error occurred while deleting the document');
        }
    };

    if (loading) {
        return (<Spinner />);
    } else {
        return (
            <React.Fragment>
                <Container>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <Button color="primary" style={{ width: '100%' }} onClick={toggleModal}>+ New</Button>
                        </Col>
                        <Col></Col>
                        <Col>
                            <Input
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Keywords</th>
                                        <th>Sort Keyword</th>
                                        <th>Type</th>
                                        <th>Location</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.documents.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.keywords}</td>
                                            <td>{item.letterToSort}</td>
                                            <td>{item.type}</td>
                                            <td><a href={'https://helpdeskforhr.com' + item.location} target='_blank' rel='noopener noreferrer'>download</a></td>
                                            <td><Button color="danger" onClick={() => handleDelete(item.location)}>Delete</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Add New Document</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="dName">Document Name</Label>
                                    <Input
                                        type="text"
                                        name="dName"
                                        id="dName"
                                        value={formData.dName}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="dkeywords">Keywords</Label>
                                    <Input
                                        type="text"
                                        name="dkeywords"
                                        id="dkeywords"
                                        value={formData.dkeywords}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="sortLetter">Sort Letter</Label>
                                    <Input
                                        type="text"
                                        name="sortLetter"
                                        id="sortLetter"
                                        value={formData.sortLetter}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="dtype">Type</Label>
                                    <Input
                                        type="text"
                                        name="dtype"
                                        id="dtype"
                                        value={formData.dtype}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="folderLetter">Folder Letter</Label>
                                    <Input
                                        type="text"
                                        name="folderLetter"
                                        id="folderLetter"
                                        value={formData.folderLetter}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="sampleFile">File</Label>
                                    <Input
                                        type="file"
                                        name="sampleFile"
                                        id="sampleFile"
                                        onChange={handleFileChange}
                                        required
                                    />
                                </FormGroup>
                                <ModalFooter>
                                    <Button color="primary" type="submit">Submit <Spinner size="sm" style={{display: uploading ? 'inline-block':'none'}}/></Button>
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </React.Fragment>
        );
    }
};

export default FormsAndDocuments;
