import Index1 from "./pages/Index1/Index1";
import Index2 from "./pages/Index2/Index2";
import Store from "./pages/Store/Store";
import Login from "./pages/Auth/login";
import SignUp from "./pages/Auth/signup";
import PasswordForget from "./pages/Auth/password_forget";
import AdminHome from "./pages/Admin/AdminHome";

const routes = [
  //Auth
  // { path: "/signup", component: <SignUp /> },
  { path: "/login", component: <Login /> },
  { path: "/admin", component: <AdminHome /> },
  // { path: "/index9", component: <Index9 /> },
  // { path: "/index8", component: <Index8 /> },
  // { path: "/index7", component: <Index7 /> },
  // { path: "/index6", component: <Index6 /> },
  // { path: "/index5", component: <Index5 /> },
  // { path: "/index4", component: <Index4 /> },
  { path: "/store", component: <Store /> },
  { path: "/aboutus", component: <Index2 /> },
  { path: "/", component: <Index1 /> },
];

export default routes;
