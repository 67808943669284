import React, { useEffect, useState } from "react";
import { Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Blog Box
import BlogBox from "./blog-box";
import { Link } from "react-router-dom";

const Blog = () => {
  const [feedArr, setFeedArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetch("https://hdhr-apps-api.herokuapp.com/blog-feed")
      .then((response) => response.json())
      .then((feed) => {
        console.log(feed);
        setFeedArr(feed);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog feed:", error);
      });
  }, []);

  const toggle = () => setModal(!modal); ;

  const handleSubmit = async (e) => {
    setSending(true)
    e.preventDefault();
    // Validate email
    if (!email) {
      setErrorMessage("Email is required");
      return;
    }
    // Add other validations as needed

    try {
      const response = await fetch('https://hdhr-apps-api.herokuapp.com/ac/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstName, lastName }),
      });
      const result = await response.json();
      if (response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage("");
        setSending(false)
      } else {
        setErrorMessage(result.error || "Failed to sign up");
        setSuccessMessage("");
        setSending(false)
      }
    } catch (error) {
      setErrorMessage("Failed to sign up");
      setSuccessMessage("");
      setSending(false)
    }
  };

  return (
    <React.Fragment>
      <section className="section bg-light active" id="blog">
        <Container>
          <SectionTitle
            title="Latest News"
            description="Stay up to date with relavent HR law news in our latest blog posts."
          />

          <Row className="mt-4">
            {loading && <p>Loading..</p>}

            {loading === false &&
              feedArr.map((i, index) => {
                if (index < 3) {
                  return <BlogBox blog={i} key={index} />;
                }
              })}
          </Row>
          <Container className="text-center">
            <Button color="primary" className="mt-4 waves-effect waves-light" onClick={toggle}>
              Sign Up For Free Updates<i className="mdi mdi-arrow-right"></i>
            </Button>
          </Container>
        </Container>
      </section>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Sign Up For Free Updates</ModalHeader>
        <ModalBody>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}
          {successMessage === '' && <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" type="submit">
              Submit
             &nbsp; <Spinner style={{display: sending ? 'inline-block':'none'} } color="white" size={'sm'}/>
            </Button>
          </Form>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Blog;

