import React, { Component } from "react";
import { Col, Button } from "reactstrap";
import '../../assets/css/service-box.css'; // Import your custom CSS file

class ServiceBox extends Component {
  render() {
    return (
      <React.Fragment>
        <Col lg="4" className="mt-4 d-flex align-items-stretch">
          <div className="services-box">
            <div className="text-center">
              <img
                src={this.props.icon}
                width="150px"
                height="auto"
                alt="Service Icon"
                className="mb-4"
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <h4>{this.props.title}</h4>
              <p className="pt-2 text-muted">{this.props.description}</p>
              <Button 
                color="primary"
                className="mt-3"
                onClick={() => window.open(this.props.link, "_blank")}
              >
                Visit Website
              </Button>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default ServiceBox;
