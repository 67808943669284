import React, { Component, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Testimonial Box
import TestimonialBox from "./testimonial-box";
import axios from "axios";

const Testimonials = ()=>{
  // state = {
  //   testimonials: [
  //     {
  //       id: 1,
  //       image: "assets/images/testimonials/user-1.jpg",
  //       name: "DENNIS WILLIAMS",
  //       cmpName: "Charleston",
  //       message:
  //         "“I feel confident imposing change on myself. It's a lot more fun progressing than looking back. That's why I ultricies enim at malesuada nibh diam on tortor neaded to throw curve balls.”",
  //     },
  //     {
  //       id: 2,
  //       image: "assets/images/testimonials/user-2.jpg",
  //       name: "LAURIE BELL",
  //       cmpName: "Worcester",
  //       message:
  //         "“Our task must be to free ourselves by widening our circle of compassion to embrace all living creatures and the whole of quis consectetur nunc sit amet semper justo. nature and its beauty.”",
  //     },
  //     {
  //       id: 3,
  //       image: "assets/images/testimonials/user-3.jpg",
  //       name: "HOWARD KELLEY",
  //       cmpName: "Lynchburg",
  //       message:
  //         "“I've learned that people will forget what you said, people will forget what you did, but people will never forget how donec in efficitur lectus, nec lobortis metus you made them feel.”",
  //     },
  //   ],
  // };
  const [reviewsArr, setReviewsArr] = useState([]);
  useEffect(() => {
    async function fetchReviews() {
      try {
        const response = await axios.get('https://hdhr-apps-api.herokuapp.com/api/reviews');
        console.log(response.data);
        setReviewsArr(response.data)
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    }

    fetchReviews();
  }, []);
 
    return (
      <React.Fragment>
        <section className="section" id="testi">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="What they've said"
              description="  Hear from our satisfied clients about how HelpDesk for HR has transformed their HR processes and compliance management."
            />

            <Row className="mt-5">
              {/* render testimonials box */}

                 {reviewsArr.length > 0 && (
                  
                  reviewsArr.map((i, index)=>{
                    if(index < 3){
                    
                    return ( <TestimonialBox key={index} testimonial={i} />)
                    
                  }
                  })
                  )
                  }
             
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }

export default Testimonials;
