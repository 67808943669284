import React, { useEffect, useState } from "react";
import { Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Blog Box
import StoreBox from "./store-box";
import { Link } from "react-router-dom";

const StoreFront = () => {
  const [feedArr, setFeedArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // useEffect(() => {
  //   fetch("https://hdhr-apps-api.herokuapp.com/blog-feed")
  //     .then((response) => response.json())
  //     .then((feed) => {
  //       console.log(feed);
  //       setFeedArr(feed);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching blog feed:", error);
  //     });
  // }, []);

  let products = [
    {
      id:0,
      name: 'HelpDesk for HR (HelpDesk Suites Special Price)',
      description:`<p>We&rsquo;re excited that you are interested in our services! We know HelpDeskforHR.com &ndash; is going to make life easier for HR Pros across the nation.</p>
    
    <h4><strong>Live Web Calls and Conversations</strong></h4>
    
    <p>Some call these Calls &ldquo;office hours&rdquo;. But, we want our members to crush HR so we developed Boss Calls&trade;! Our Bosses meet virtually 24 times a year to discuss the hot topics of the day. Each Call is HRCI and SHRM approved for 1.5 recertification credits. You will learn enough from this feature alone to cover your purchase price. Value $499 per year. Included in membership.</p>
    
    <h4><strong>Curated Content HR Vault</strong></h4>
    
    <p>The Vault is your ever-expanding HR knowledgebase. We have stored 20+ years of HR experience in The Vault because when you find HR resources that are accurate, up-to-date, and helpful, you want to keep them secure. &ndash; so you can be Audit-Secure!Value $499 year. Included in membership.</p>
    
    <h4><strong><strong><strong>Proprietary HR Applications</strong></strong></strong></h4>
    
    <p>Multiple Applications, One Goal. Over the last few years, we&rsquo;ve developed many tools that make the lives of our members much easier by getting them home on time and helping them to Be Audit-Secure. Our apps include&hellip; The Employee Handbook Creator, Interview Question Writer, Job Description Writer, Calculators, Performance Evaluation Center, Interactive Process, SOP Writer, Salary Finder, and our Forms &amp; Documents Library. Value $699 year. Included in membership.</p>
    
    <h4><strong><strong><strong>HR Email Support</strong></strong></strong></h4>
    
    <p>Monday-Friday Unlimited Email HR Support. No Question is Too Big Or Small Ask us any HR or payroll-related questions you have. Our SPHR / SCP Certified team of professionals will have the answers for you &ndash; the same day! Value $499 year. Included in membership.</p>
    
    <h4><strong><strong><strong>E-Learning and Training</strong></strong></strong></h4>
    
    <p>LEARN &gt; UNLEARN &gt; RELEARN. Enjoy access to hundreds of hours of training videos, audio recordings, and ebooks. The smart HR professional never stops learning. Value $299 year. Included in membership.</p>
    
    <h4><strong><strong><strong><strong><strong><strong>Facebook Community</strong></strong></strong></strong></strong></strong></h4>
    
    <p>A Community of Like-minded Professionals. Have a quick question you need to be answered. Want to share a crazy HR story? Looking for a certification study group? Whatever the case may be, our community of HR pros is there to help. Our community is private, only available to HelpDesk for HR members, and monitored to make sure everything is kept civil and supportive. Value: Priceless</p>
    
    `,
      price: 'See Pricing Options',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/HDHR-suqare.jpg',
      link:'https://helpdeskforhr.com/pricing/'
    },{
      id:1,
      name: 'HRCI/SHRM Prep Course',
      description:`<p>Andere Preps U is a leading provider of HRCI certification prep courses.&nbsp;This self-paced e-learning course is specifically designed to successfully prepare you to pass your PHR or SPHR or SHRM-CP or SHRM-SCP exam.</p>
    
    <p><br />
    <strong>Course Includes: </strong></p>
    
    <ul>
      <li>25+ Hours Of Interactive, Online Instruction</li>
      <li>Over 700 Quick-Testing Narrated Flash Cards</li>
      <li>1,100+ Practice Exam Questions - Games - Webinars&nbsp;</li>
      <li>Audio Conferences</li>
      <li>And More!</li>
    </ul>
    `,
      price: '$449/yr',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/APU-square.jpg',
      link:'https://andere-corp.thrivecart.com/phr-sphr-exam-prep-course/'
    },
    {
      id:2,
      name: 'Employee Training Tool-Kit',
      description:`<p>The&nbsp;<strong>Employee Training Tool-kit&nbsp;</strong>contains<strong>&nbsp;</strong>in-house DIY training tools that cover a variety of relevant topics. In this information-packed training toolkit, you will find full audio/video courses you can use to train your employees on a variety of very important topics!</p>
    
    <p><strong>This program contains 18 video courses and 10 audio courses including:</strong></p>
    
    <p><strong>Video Courses:</strong></p>
    
    <ul>
      <li>ADA - The Interactive Process</li>
      <li>ADA Basic Mini-Course</li>
      <li>Anti-harassment and Abusive Conduct for the entire country</li>
      <li>Appreciative Inquiry</li>
      <li>Diversity, Equity, and Inclusion</li>
      <li>FLSA Basics</li>
      <li>FMLA Basics</li>
      <li>How to Deal with Difficult Employees and Clients</li>
      <li>I-9 Mini-Class</li>
      <li>New Supervisor&#39;s Personal Checklist for Success</li>
      <li>Unconscious Bias</li>
      <li>Sensitivity in the Workplace</li>
      <li>Preventing Workplace Gossip</li>
      <li>Coaching and Mentoring</li>
      <li>Crisis Management</li>
      <li>Respect in the Workplace</li>
      <li>Trustbuilding and Resilience Development</li>
      <li>Accountability in the Workplace</li>
      <li>Anger Management</li>
      <li>HIPAA Compliance</li>
    </ul>
    
    <p><strong>Audio Courses:</strong></p>
    
    <ul>
      <li>Business Writing</li>
      <li>Business Etiquette</li>
      <li>Business Succession Planning</li>
      <li>Conducting Annual Employee Reviews</li>
      <li>Train the Trainer - Learn How to Train!</li>
      <li>Emotional Intelligence</li>
      <li>Time Management</li>
      <li>Office Health and Safety</li>
      <li>Office Politics for Managers</li>
      <li>Telework and Telecommuting - How to Be More Productive and Less Distracted.</li>
    </ul>
    
    <p>In addition to the audio/video files, most courses also include:</p>
    
    <ul>
      <li>Workbook</li>
      <li>Caption file</li>
      <li>Trainer/Instructor Guide (most courses)</li>
      <li>Quiz or quiz questions and answer key</li>
      <li>PowerPoint slides you can edit</li>
    </ul>
    `,
      price: 'See Pricing Options',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/ett-square.jpg',
      link:'https://employeetrainingtoolkit.com/#pricing'
    },
    {
      id:3,
      name: 'HRCI/SHRM Self Paced 4 Hour Boot-Camp',
      description:`<p>Do you want to get the PHR, SPHR, SHRM-CP, or SCP credentials behind your name but are still feeling nervous about passing the exam?</p>
    
    <p dir="ltr">Do you want to attend a Bootcamp but cannot dedicate six weeks or a full 7+ hour day to attend a live Bootcamp?</p>
    
    <p dir="ltr">If so, &nbsp;you are perfect for this&nbsp;<strong>Self-Paced Exam Prep Bootcamp</strong>!</p>
    
    <p>Join&nbsp;<strong>Lisa Smith, SPHR, SCP</strong>&nbsp;for a 4-hour Exam Prep Bootcamp.&nbsp;</p>
    
    <p>During this&nbsp;<strong>4-hour</strong>&nbsp;session you will learn:</p>
    
    <ul>
      <li>The 10-Keys to Passing Your Exam</li>
      <li>Must-Know Principles, Models, and Theories</li>
      <li>Essential Employment Laws</li>
      <li>Essential Case Law</li>
      <li>HR Math</li>
      <li>And&nbsp;<strong>SO MUCH</strong>&nbsp;more..</li>
    </ul>
    
    <p dir="ltr"><strong>With This Bootcamp You Will Get:</strong></p>
    
    <ul>
      <li>&nbsp;<strong>6 months</strong>&nbsp;unlimited access to all training materials</li>
      <li>Includes&nbsp;<strong>4 hours&nbsp;</strong>of video training sessions &ndash; A replay of a recent live Bootcamp with closed caption features.</li>
      <li>Your instructor is Lisa Smith, SPHR, SHRM-SCP, and EEOC Certified Investigator. Lisa has&nbsp;<strong>over 20 years in HR</strong>&nbsp;and is the CEO of HelpDeskforHR.com</li>
      <li><strong>Handouts</strong>&nbsp;for each session</li>
      <li><strong>Slides</strong>&nbsp;for each session in pdf format</li>
      <li>Final&nbsp;<strong>100 question practice exam&nbsp;</strong>with full explanations of each answer in pdf format</li>
    </ul>`,
      price: '$249/ 1 year access',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/boot-camp.jpg',
      link:'https://andereprepsu.com/registration/self-paced-exam-prep-bootcamp-for-aphr-phr-sphr-cp-and-scp/'
    }
    ,
    {
      id:4,
      name: '140 HR FAQs - eBook',
      description:`<p>The&nbsp;140&nbsp;FAQs&nbsp;cover&nbsp;<strong>13 sections</strong>&nbsp;of information:</p>
    
    <ol>
      <li>Recruiting and Hiring</li>
      <li>Legally Compliant Interviews</li>
      <li>Writing Effective Job Postings</li>
      <li>Writing Compliant Job Descriptions</li>
      <li>The Onboarding Process</li>
      <li>Completing the I-9</li>
      <li>Employee Termination</li>
      <li>Handling Difficult Conversations</li>
      <li>FMLA</li>
      <li>ADA</li>
      <li>FLSA</li>
      <li>Performance and Conduct</li>
      <li>Workplace Investigations</li>
    </ol>`,
      price: '$29.99',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/FAQ-Book-cover.jpg',
      link:'https://andere-corp.thrivecart.com/140-faqs-from-hdforhr/'
    }
    ,
    {
      id:5,
      name: '160  Difficult Conversation Starters',
      description:`<p><strong>160&nbsp;Difficult Conversation Starters</strong>&nbsp;covers&nbsp;<strong>8 topics of conversation.</strong></p>
    
    <ol>
      <li>Difficult Conversations with Coworkers or Employees (50)</li>
      <li>Requesting a&nbsp;Pay Raise (20)</li>
      <li>Employee Hygiene and Body Odor (10)</li>
      <li>Restroom Cleanliness (10)</li>
      <li>Poor Performance (10)</li>
      <li>Addressing Harassment and Discrimination with the Alleged Offender (20)</li>
      <li>Suspected Domestic Violence (20)</li>
      <li>Ways to just say NO! (20)</li>
    </ol>
    `,
      price: '$14.99',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/Convo-ebook.jpg',
      link:'https://andere-corp.thrivecart.com/160-difficult-conversation-starters/'
    }
    ,
    {
      id:6,
      name: '186 HRCI and SHRM Exam Prep Questions and Answers',
      description:`<p>Hey, Exam Preppers!</p>
    
    <p>The team at AnderePrepsU.com is offering a&nbsp;<strong>186-question practice exam</strong>&nbsp;with an&nbsp;<strong>answer key</strong>&nbsp;in pdf format.</p>
    
    <p>We have the exam broken down by functional area so you can see where your strengths and weaknesses live and begin polishing them up as you prep for a future exam. Whether sooner or later, this practice exam will give you a good idea about your odds of passing a test if you sat for the&nbsp;<strong>PHR/SPHR/CP/SCP</strong>&nbsp;today.</p>
    
    <p><strong>Andere Preps U! is an Approved Exam Prep Provider.</strong></p>
    
    <p>Happy Prepping!</p>
    `,
      price: '$19.99 (reg. $29.97)',
      image:'https://helpdesksuites.com/wp-content/uploads/2023/06/examp-prep-ebook.jpg',
      link:'https://andere-corp.thrivecart.com/186-hr-exam-prep-questions-answers/'
    }
    
    
    ]

  const toggle = () => setModal(!modal); ;

  const handleSubmit = async (e) => {
    setSending(true)
    e.preventDefault();
    // Validate email
    if (!email) {
      setErrorMessage("Email is required");
      return;
    }
    // Add other validations as needed

    try {
      const response = await fetch('https://hdhr-apps-api.herokuapp.com/ac/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstName, lastName }),
      });
      const result = await response.json();
      if (response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage("");
        setSending(false)
      } else {
        setErrorMessage(result.error || "Failed to sign up");
        setSuccessMessage("");
        setSending(false)
      }
    } catch (error) {
      setErrorMessage("Failed to sign up");
      setSuccessMessage("");
      setSending(false)
    }
  };

  return (
    <React.Fragment>
      <section className="section bg-light active" id="blog">
        <Container>
          <SectionTitle
            title="Store"
            description="Browse our products here"
          />

          <Row className="mt-4">
            {loading && <p>Loading..</p>}

            {loading === false &&
              products.map((i, index) => {
              
                  return <StoreBox item={i} key={index} />;
                
              })}
          </Row>
          {/* <Container className="text-center">
            <Button color="primary" className="mt-4 waves-effect waves-light" onClick={toggle}>
              Sign Up For Free Updates<i className="mdi mdi-arrow-right"></i>
            </Button>
          </Container> */}
        </Container>
      </section>

      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Sign Up For Free Updates</ModalHeader>
        <ModalBody>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}
          {successMessage === '' && <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" type="submit">
              Submit
             &nbsp; <Spinner style={{display: sending ? 'inline-block':'none'} } color="white" size={'sm'}/>
            </Button>
          </Form>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal> */}
    </React.Fragment>
  );
};

export default StoreFront;

