import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const BlogBox = ({blog})=> {
useEffect(()=>{
  console.log(blog)
})
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="blog-box mt-4">
            <img
              src={blog.yoast_head_json.og_image[0].url}
              className="img-fluid rounded"
              alt=""
            />
            <div>
              {/* <h5 className="mt-4 text-muted">{blog.title.rendered}</h5> */}
              <h4 className="mt-3">
                <Link to={blog.link} className="blog-title">
                  {" "}
                  {blog.title.rendered}{" "}
                </Link>
              </h4>
              <p className="text-muted"><div dangerouslySetInnerHTML={{__html:blog.excerpt.rendered}}></div></p>
              <div className="mt-3">
                <Link to={blog.link} className="read-btn">
                  Read More <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }


export default BlogBox;
