import { Divider, ListItemText, MenuItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";


export const AdminCards = (item)=>{

    return(
        <div>
        <MenuItem onClick={()=> {
           let conf = window.confirm('Are you sure you want to do this? Any changes you have made will not be saved.')
           if(conf){
            item.link()
           } else {
            
           }
      
        }}>
            <ListItemText>
            <Typography variant='button'>{item.name}</Typography>
            </ListItemText>
          </MenuItem>
          <Divider/>
          </div>
    )

}