import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";
import '../../assets/css/service-box.css';

const StoreBox = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    console.log(item);
  }, [item]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const descriptionPreview = isExpanded ? item.description : item.description.substring(0, 150) + '...';

  return (
    <React.Fragment>
      <Col className="mb-4" lg="4">
        <div className="services-box">
          <div className="blog-box mt-4">
            <img
              src={item.image}
              className="img-fluid rounded"
              alt=""
            />
            <div>
              <h4 className="mt-3">
                <Link to={item.link} className="blog-title">
                  {item.title}
                </Link>
              </h4>
              <p className="text-muted">
                <div dangerouslySetInnerHTML={{ __html: descriptionPreview }}></div>
              </p>
              <div className="mt-3">
              <Link onClick={toggleExpanded} className="read-btn">
              {isExpanded ? 'Read Less' : 'Read More'} <i className="mdi mdi-arrow-right"></i>
              </Link>

            
            
              </div>
              
            </div>
          </div>
          <p className="text-muted"><b>Price:</b> {item.price}</p>
          <Button 
                color="primary"
                className="mt-3"
                onClick={() => window.open(item.link, "_blank")}
              >
                Purchase
              </Button>
        </div>
        
      </Col>
    </React.Fragment>
  );
};

export default StoreBox;
