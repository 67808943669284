import React, {useState} from "react";
import { Container, Row, Col, Button, FormFeedback, Form, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import SectionTitle from "../common/section-title";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ContactUs = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      subject: Yup.string().required("Please Enter Your Subject"),
      message: Yup.string().required("Please Enter Your Message")
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch('https://hdhr-apps-api.herokuapp.com/ac/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          setSnackbarMessage('Message sent successfully');
          setSnackbarSeverity('success');
          resetForm();
        } else {
          setSnackbarMessage('Failed to send message. Please try again.');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage('Failed to send message. Please try again.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    }
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <SectionTitle
            title="Let’s connect."
            description="Are you ready to revolutionize your HR experience? Let's embark on an exciting journey together!"
          />
          <Row>
          <Col lg="6">
                <div className="">
                                       
                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1"></p>

                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1">We can't wait to connect with you and share the incredible world of Andere HR. Our team is eagerly standing by, ready to provide you with all the information you need and answer any questions you may have.</p>
                        
                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1">Take the first step towards transforming your HR processes by filling out our simple form. Here's what you can expect:</p>
                        
                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1">
                        <ul>
                            <li>Discover the game-changing power of HelpDesk for HR and our other cutting-edge products and services through a personalized demo tailored just for you. We'll also provide transparent pricing information so you can make an informed decision.</li>
                            <li>Connect with our passionate team of HR experts who are dedicated to helping you achieve your goals. Let's explore the possibilities of partnering with Andere HR and unlocking your organization's full potential.</li>
                         
                        </ul>
                        </p>
                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1">Don't miss out on this opportunity to elevate your HR game to new heights. Fill out the form now, and let's start this adventure together!</p>
                        <p style={{textAlign:'start'}} className="section-subtitle font-secondary  pt-1">We can't wait to hear from you and be a part of your HR success story. Get ready to experience the Andere HR difference!</p>
                    

                </div>
              </Col>
            <Col lg="6">
              <div className="custom-form mt-4 pt-4">
                <p id="error-msg"></p>
                <div id="message"></div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                  <Row>
                    <Col lg="6 mt-2">
                      <Input
                        name="name"
                        placeholder="Your name*"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={validation.touched.name && validation.errors.name ? true : false}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col lg="6 mt-2">
                      <Input
                        name="email"
                        placeholder="Your email*"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <Input
                        name="subject"
                        placeholder="Your Subject.."
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.subject || ""}
                        invalid={validation.touched.subject && validation.errors.subject ? true : false}
                      />
                      {validation.touched.subject && validation.errors.subject ? (
                        <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <div className="form-group">
                        <Input
                          name="message"
                          type="textarea"
                          rows="4"
                          className="form-control"
                          placeholder="Your message..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.message || ""}
                          invalid={validation.touched.message && validation.errors.message ? true : false}
                        />
                        {validation.touched.message && validation.errors.message ? (
                          <FormFeedback type="invalid">{validation.errors.message}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-end">
                      <Button className="submitBnt btn btn-primary" type="submit">
                        Send Message
                      </Button>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </section>
    </React.Fragment>
  );
}

export default ContactUs;
