import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

export const FooterLinks = ()=> {

    let socials = [
      { icon: "mdi mdi-facebook", link: "#" },
      { icon: "mdi mdi-twitter", link: "https://x.com/helpdeskforhr" },
      { icon: "mdi mdi-linkedin", link: "#" },
      { icon: "mdi mdi-account-cog", link: "/login" },
     
    ]
  
 
    return (
      <React.Fragment>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg="12">
                <div className="float-sm-start pull-none">
                  <p className="copy-rights  mb-3 mb-sm-0">
                    {new Date().getFullYear()} © Andere HR
                  </p>
                </div>
                <div className="float-sm-end pull-none copyright ">
                  <ul className="list-inline d-flex flex-wrap social m-0">
                    {socials.map((social, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to={social.link} className="social-icon">
                          <i className={social.icon}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="clearfix"></div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  
}

export default FooterLinks;
