import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class WebsiteDescription extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg="12" className="text-center">
                <h2 className="text-white">Andere Preps U! <h2 className="text-white">Human Resources Certification Exam Prep</h2>
</h2>
                <p style={{textAlign:'start'}} className="pt-3 home-desc mx-auto">
                Introducing Andere Preps U! - your ultimate partner in achieving HR certification success! 
                </p>

                <p style={{textAlign:'start'}} className="pt-3 home-desc mx-auto">
                Our passionate team is committed to empowering HR professionals like you to confidently prepare for and ace your chosen HRCI or SHRM certification exam. We offer a comprehensive suite of resources tailored to your needs, including our immersive full prep course, bi-annual 12-week study groups, and convenient live and on-demand Bootcamps. As approved providers with both HRCI and SHRM, you can trust in the quality and effectiveness of our programs.
                </p>

                <p style={{textAlign:'start'}} className="pt-3 home-desc mx-auto">
                Join the Andere Preps U! community today and unlock your full potential in the world of human resources. Let us be your guide on this transformative journey towards professional growth, recognition, and limitless opportunities. Get ready to excel with Andere Preps U!
                </p>
                <Link
                  to="https://andereprepsu.com"
                  className="btn btn-light mt-5 waves-effect waves-light"
                  target="_blank"
                >
                  View Our Certification Prep Options
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDescription;
