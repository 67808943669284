import React, { Component } from "react";
import { Col } from "reactstrap";

const TestimonialBox = ({testimonial})=>{
  
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="testimonial-box mt-4">
            <div className="testimonial-decs p-4">
              <div className="testi-icon">
                <i className="mdi mdi-format-quote-open display-1"></i>
              </div>
              <img
                src={testimonial.profile_photo_url}
                alt=""
                className="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4"
              />
              <div className="p-1">
                <h5 className="text-center text-uppercase mb-3">
                  {testimonial.author_name} -{" "}
                  <span className="text-muted text-capitalize">
                    {testimonial.cmpName}
                  </span>
                </h5>
                <p className="text-muted text-center mb-0">
                  {testimonial.text}
                </p>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }


export default TestimonialBox;
