import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import hdhrLogo from '../../assets/images/hdhr-logo.png';

import ModalSection from "../../components/common/ModalSection";

const Section = () => {
  const [sending, setSending] = useState(false);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    setSending(true);
    e.preventDefault();

    if (!email) {
      setErrorMessage("Email is required");
      setSending(false);
      return;
    }

    try {
      const response = await fetch('https://hdhr-apps-api.herokuapp.com/ac/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name:`${firstName} ${lastName}` }),
      });

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage("");
      } else {
        setErrorMessage(result.error || "Failed to sign up");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("Failed to sign up");
      setSuccessMessage("");
    } finally {
      setSending(false);
    }
  };

  return (
    <React.Fragment>
      <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg"> 
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col
              lg={{ size: 8, offset: 2 }}
              className="text-white text-center"
            >
              <h4 className="home-small-title">Andere HR</h4>
              <h1 className="home-title">
                Simplify Your HR Processes and <span className="home-title">Be Audit Secure&#8482;</span>
              </h1>
              <p className="pt-3 home-desc mx-auto">
                At Andere HR, we specialize in creating efficient and user-friendly tools for HR professionals. From employee training and performance evaluations to seamless I-9 compliance, our solutions are designed to streamline your processes and ensure compliance, helping you focus on what truly matters – your people.
              </p>

              <Container className="text-center">
            <Button color="primary" className="mt-4 waves-effect waves-light" onClick={toggle}>
              Sign Up For Free Updates<i className="mdi mdi-arrow-right"></i>
            </Button>
          </Container>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Sign Up For Free Updates</ModalHeader>
          <ModalBody>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}
            {successMessage === '' && (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  Submit
                  &nbsp; <Spinner style={{display: sending ? 'inline-block':'none'}} color="white" size="sm" />
                </Button>
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
