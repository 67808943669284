import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import hdhrLogo from '../../assets/images/hdhr-logo.png';

class Products extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="products">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>HelpDesk for HR</h3>
                  <p className="text-muted home-desc mx-auto">
  HelpDesk for HR provides a comprehensive suite of tools designed to streamline your HR operations. Experience the benefits of our feature-rich platform:
</p>
<ul className="text-muted list-unstyled mt-4 features-item-list">
  <li><b>Live Web Calls:</b> Join our Boss Calls™ for real-time expert advice and HR insights.</li>
  <li><b>Curated HR Vault:</b> Access a vast repository of accurate, up-to-date HR resources.</li>
  <li><b>Proprietary HR Applications:</b> Utilize tools like the Employee Handbook Creator and Job Description Writer.</li>
  <li><b>Mobile App:</b> Manage HR tasks on the go with our user-friendly mobile application.</li>
  <li><b>Unlimited Email Support:</b> Get quick answers to all your HR and payroll-related questions.</li>
  <li><b>Training and Development:</b> Enjoy access to hundreds of hours of training videos and materials.</li>
  <li><b>Poster Compliance:</b> Stay compliant with monthly updates to federal, state, and local posters.</li>
</ul>
                  <Link
                    to="https://helpdeskforhr.com"
                    target="_blank"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src={hdhrLogo}
                    alt="HelpDesk for HR"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Products;
