import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const GetStarted = () => {

    
  return (
    <React.Fragment>
      <section className="section section-lg bg-get-start">
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col lg={{ size: 8, offset: 2 }} className="text-center">
              <h1 className="get-started-title text-white">Powered by Partnerships</h1>
              <div className="section-title-border mt-4 bg-white"></div>
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                <a style={{ color: 'white' }} href='https://trustmineral.com/partners/' target='_blank' rel="noopener noreferrer">
                  More than 20,000 businesses get help with their HR and compliance needs thanks to our network of partners.
                </a>
              </p>
              
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                At HelpDeskforHR.com, we’re dedicated to providing businesses with the expert guidance and resources they need to effectively manage their HR and compliance challenges. Our comprehensive platform, powered by a team of certified HR professionals, offers a one-stop solution for all your clients’ HR needs.
              </p>

              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                By partnering with HelpDeskforHR.com, you’ll be able to offer your clients a trusted place to turn for help with everything from creating employee handbooks and policies to navigating complex compliance issues. Our platform seamlessly integrates with your existing offerings, allowing you to deliver exceptional service and value under your own brand.
              </p>
              
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                When you partner with us, you’ll enjoy benefits such as:
              </p>
              
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                <ol>
                  <li>Enhanced client relationships: By providing your clients with access to expert HR and compliance support, you’ll demonstrate your commitment to their success and foster stronger, more loyal relationships.</li>
                  <li>Increased revenue: Offering our services as part of your own portfolio can open up new revenue streams and significantly boost your bottom line.</li>
                  <li>Seamless integration: Our platform is designed to integrate smoothly with your existing offerings, making it easy for you to deliver comprehensive HR and compliance solutions to your clients.</li>
                  <li>Expert support: Your clients will have access to our team of certified HR professionals, who are always ready to provide personalized guidance and support.</li>
                  <li>Customizable solutions: We offer flexible partnership options, allowing you to tailor our services to meet the unique needs of your clients and your business.</li>
                </ol>
              </p>
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                By partnering with HelpDeskforHR.com, you’ll be able to provide your clients with a high level of expert support and guidance while strengthening your own brand and bottom line.
              </p>
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                Don’t miss this opportunity to empower your clients and grow your business.
              </p>
              <p style={{ textAlign: 'start' }} className="section-subtitle font-secondary text-white pt-1">
                Contact HelpDeskforHR.com today to learn more about our partnership opportunities and how we can help you take your services to the next level.
              </p>

              <a onClick={() => window.scrollTo(0, document.body.scrollHeight)} className="btn btn-light waves-effect mt-4">
                Get Started <i className="mdi mdi-arrow-right"></i>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default GetStarted;
