import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import i9HelpDeskLogo from '../../assets/images/i9HelpDesk-logo.png';
import SectionTitle from "../common/section-title";
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Employee Training Tool-Kit",
        icon: "https://cdn-636529e3c1ac189bf80d1bcf.closte.com/wp-content/uploads/2023/03/cropped-icon6.png",
        description:
          "Elevate your training program with the Employee Training Toolkit. Our innovative platform offers flexible DIY or LMS options, intuitive single video and audio training, dynamic modules, and powerful tracking features. Create custom training with our Training Builder and gain insights with comprehensive reports. Experience seamless training setup and delivery. Choose the plan that fits your needs and start optimizing your training efforts today!",
          link:'https://employeetrainingtoolkit.com'
      },
      {
        title: "Performance Evaluation Center",
        icon: 'https://www.performanceevaluationcenter.com/wp-content/uploads/2023/10/cropped-PEC-500px-1.png',
        description:
          "Unlock the full potential of your team with Performance Evaluation Center. Our platform provides a comprehensive suite of tools for employee performance evaluations, including a holistic dashboard, easy employee management, customizable evaluations, structured improvement plans, and insightful reports. Seamlessly schedule and manage evaluations, set goals, and track progress effortlessly. Elevate your evaluations and your success with Performance Evaluation. Sign up today!",
          link:'https://performanceevaluationcenter.com'
        },
      {
        title: "I9 HelpDesk",
        icon: i9HelpDeskLogo,
        description:
          "Simplify I-9 compliance with I9HelpDesk. Our cloud-based system ensures effortless management of I-9 and E-Verify processes, accessible anytime, anywhere. Enjoy paperless management, enhanced security, and seamless E-Verify integration. Adapt to remote work with video document inspection, and benefit from transparent pricing with no hidden costs. Manage your I-9s on the go with our mobile app. Choose the right plan for your needs and stay compliant with ease. Sign up today and transform your compliance operations!",
          link:'https://i9helpdesk.com'
        }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            <SectionTitle
              title="OUR OTHER PRODUCTS AND SERVICES"
              description="Explore our range of innovative solutions designed to enhance your HR operations."
            />

            <Row className="mt-5">
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                  link={service.link}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
