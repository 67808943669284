import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Lisa Smith",
        image: "assets/images/team/img-1.jpg",
        post: "CEO",
      },
      {
        id: 2,
        name: "Rick Smith",
        image: "assets/images/team/img-2.jpg",
        post: "Director of Marketing",
      },
      {
        id: 3,
        name: "Mason Merrell",
        image: "assets/images/team/img-3.jpg",
        post: "DEVELOPER",
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto">
                  <h2 className="font-weight-light">
                  About Andere HR
                  </h2>
                  <p className="text-muted pt-3">
                  At Andere HR, we are passionate about revolutionizing the way businesses manage their human resources. With over 30 years of experience in the industry, we have witnessed firsthand the challenges and complexities that HR professionals face every day. That's why we have made it our mission to provide innovative solutions that simplify HR processes, empower teams, and drive organizational success.
                  </p>

                  <p className="text-muted pt-3">
                  Our journey began with a vision to create a company that truly understands the needs of HR professionals. We started by listening to their pain points, their aspirations, and their dreams for a better way to manage HR and remain compliant in an ever-changing and evolving industry. Armed with this knowledge, we set out to develop cutting-edge products and services that address these challenges head-on.                  
                  </p>

                  <p className="text-muted pt-3">
                  One of our flagship offerings is HelpDesk for HR, a game-changing solution that streamlines HR operations and enhances employee engagement. With HelpDesk for HR, businesses can easily automate routine tasks, get the advisory services they need, and access a wealth of HR knowledge at their fingertips.                 
                  </p>

                  <p className="text-muted pt-3">
                  But we don't stop there. We believe in the power of education and empowerment. That's why we have created Andere Preps U!, a comprehensive learning platform dedicated to helping HR professionals achieve their certification goals. Whether you're preparing for an HRCI or SHRM certification exam, our expert-led courses, study groups, and bootcamps provide the knowledge and support you need to succeed.                 
                  </p>

                  <p className="text-muted pt-3">
                  At Andere HR, we are more than just a company – we are a community of HR enthusiasts, thought leaders, and change-makers. We are committed to fostering a culture of collaboration, innovation, and continuous learning. Our team of seasoned HR professionals brings a wealth of experience and expertise to the table, ensuring that our solutions are grounded in best practices and deliver measurable results.                  
                  </p>

                  <p className="text-muted pt-3">
                  We believe in the power of partnerships. We work closely with our clients to understand their unique challenges and tailor our solutions to meet their specific needs. Whether you're a small business looking to streamline your HR processes or a large enterprise seeking to transform your HR function, we are here to help you every step of the way.                  
                  </p>

                  <p className="text-muted pt-3">
                  At Andere HR, we are driven by a relentless pursuit of excellence. We constantly push the boundaries of what's possible, seeking out new ways to innovate and improve. We are committed to staying at the forefront of the HR support industry, leveraging the latest technologies and best practices to deliver unparalleled value to our clients.                  
                  </p>


                  <p className="text-muted pt-3">
                  </p>

                  <h3 className="font-weight-light">
                Be Audit-Secure™: Your Shield Against HR Compliance Risks   
                </h3>
                <p className="text-muted pt-3">
                HR compliance is more critical than ever in today's complex business landscape. With constantly evolving regulations, the threat of audits, and the potential for costly penalties, organizations need a reliable solution to navigate these challenges confidently. That's where the Be Audit-Secure™ system of HR compliance comes in.
                </p>

                  <p className="text-muted pt-3">
                  Be Audit-Secure™ is a groundbreaking system designed to provide you with the ultimate protection against HR compliance risks. It acts as your vigilant guardian, proactively identifying potential issues and guiding you toward maintaining a compliant HR environment.                  </p>

                  {/* <p className="text-muted pt-3">
                 <a href="https://helpdeskforhr.com/mp-courses/how-to-be-audit-secure/ " target="_blank">Here is a short course on how to Be Audit-Secure™.</a></p> */}

                  <p className="text-muted pt-3">
                  Be Audit-Secure™ is more than just a compliance tool; it's your partner in creating a culture of compliance within your organization. By embedding compliance into your daily HR operations, you will foster a proactive approach to risk management, ensuring that compliance becomes second nature to your team.
                  </p>

                  <p className="text-muted pt-3">
                  Don't leave your HR compliance to chance. Embrace the power of Be Audit-Secure™ and experience the peace of mind that comes with knowing your organization is protected against compliance risks. 
                  </p>

                  
                  <p className="text-muted pt-3">
                  Join us on this exciting journey and experience the Andere HR difference. Together, let's unlock the full potential of your HR function and drive your organization forward. Contact us today to learn more about how we can help you achieve your HR goals.                  
                  </p>
                </div>

             

              </Col>
            </Row>
            {/* <Row className="mt-5 text-center">
            <h3 className="font-weight-light pb-5">
              Our Team  
                </h3>
              {/* Render Team members */}
              {/* {this.state.members.map((member, key) => (
                <TeamBox
                  key={key}
                  name={member.name}
                  image={member.image}
                  post={member.post}
                />
              ))} */}
              {/* </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
